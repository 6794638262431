import { makeStyles } from "@mui/styles";

const CollectionsStyles = makeStyles((theme) => ({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "red",
      },
    },
  },

  card1: {
    cursor: "pointer !important",
    boxShadow: "0px 3px 6px 1px #9999991F !important",
    // background: "#fff !important",

    "&:hover": {
      boxShadow: "0px 15px 26px rgb(0 0 0 / 44%) !important",
    },
    "& .MuiCardActions-root": {
      opacity: "0",
    },
    "&:hover .MuiCardActions-root ": {
      // display:'block',
      opacity: "1",
      // padding: "10px",
      textAlign: "center !important",
      margin: "auto !important",
      fontFamily: "InterRegular !important",
      fontWeight: "600 !important",
      fontSize: "15px !important",
      lineHeight: "12px !important",
      cursor: "pointer !important",
      color: "#fff !important",
      display: "flex",
      justifyContent: "center",
      background: "#AE0019 !important",
    },
    "&:hover .MuiCardMedia-root ": {
      transform: "scale(1.1)",
      transition: "all 0.5s ease 0s",
    },

    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: "1rem !important",
    // },
  },
  typoStyles: {
    fontFamily: "InterMedium !important",
    fontSize: "10px !important",
    fontWeight: "600",
    // color: "#333333",
  },
  typoStyles1: {
    fontFamily: "InterRegular !important",
    fontSize: "14px !important",
    fontWeight: "400",
    color: "#666666",
  },

  infiniteSrlStyle: {
    // minWidth: "95vw",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },

  //   tabStyles1: {
  //     minWidth: "80vw !important",
  //     [theme.breakpoints.down("md")]: {
  //       minWidth: "100vw !important",
  //     },
  //   },

  main1CardStyle: {
    maxHeight: "100px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100px !important",
    },
  },

  main2CardStyle: {
    maxHeight: "100px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100px !important",
    },
  },

  itemTabStyle: {
    fontFamily: "InterRegular !important",

    fontSize: "18px !important",
    fontWeight: "600 !important",
    color: "#DB303F !important",
  },
  itemGridStyle: {
    display: "flex !important",
    justifyContent: "center !important",
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: "auto !important",
    //   maxWidth: "100% !important",
    //   margin: "auto !important",
    //   alignItems: "center !important",
    //   display: "flex !important",
    //   float: "right !important",
    // },
    // [theme.breakpoints.only("md")]: {
    //   marginLeft: "auto !important",
    //   maxWidth: "100% !important",
    //   margin: "auto !important",
    //   alignItems: "center !important",
    //   display: "flex !important",
    //   float: "right !important",
    // },
  },
  contentStyle: {
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "0px !important"
    },
  },
  itemImgStyle: {
    marginTop: "0.3rem",
    // height: "15vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.4rem !important",
      // paddingBottom: "0px !important"
    },
  },
  itemImgStyle2: {
    marginTop: "0.3rem !important",
    // height: "15vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-0.1rem !important",
      // paddingBottom: "0px !important"
    },
  },
  itemImgStyle1: {
    borderRadius: "25px",
    background: "skyblue",
    objectFit: "contain",
  },
  itemHeaderTextStyle: {
    textAlign: "left",
    padding: 0,
    width: "300px !important",
  },
  itemHeaderStyle1: {
    fontFamily: "InterSemiBold !important",
    marginTop: "0.3rem !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    lineHeight: "24px !important",
    letterSpacing: "0px !important",
    textAlign: "left !important",
    // color: "#333333 !important",
    width: "300px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.6rem !important",
      fontSize: "14px !important",
    },
  },

  itemHeaderTextStyle1: {
    fontFamily: "InterSemiBold !important",
    marginLeft: "-0.2rem !important",
    marginTop: "1rem !important",
    fontSize: "16px !important",
    fontWeight: "600 !important",
    lineHeight: "24px !important",
    letterSpacing: "0px !important",
    textAlign: "left !important",
    // color: "#333333 !important",
    width: "300px !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-0.8rem !important",
      marginTop: "1rem !important",
      fontSize: "14px !important",
    },
  },
  itemHeaderTextStyle2: {
    fontFamily: "InterMedium !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    letterSpacing: "0px !important",
    textAlign: "left !important",
    // color: "#333333 !important",
    marginTop: "2px",
    // height: "14px",
    // width: "300px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  priceTextStyle: {
    fontFamily: "InterRegular !important",
    fontSize: "14px",
    fontWeight: "500 !important",
    // color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      fontWeight: "500",
    },
  },

  itemcardDescStyle: {
    textAlign: "left !important",
    overflow: "hidden !important",
    // height: "62px",
    marginTop: "5px !important",
    width: "100% !important",
    fontFamily: "InterRegular !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    lineHeight: "12px !important",
    letterSpacing: "0px !important",
  },
  itemcardBtnStyle: {
    backgroundColor: "#fff",
    padding: "10px",
    textColor: "#fff !important",
    textAlign: "center !important",
    display: "none",

    // '&:hover': {
    //     background: "blue",
    //     color: "#fff",
    //     textAlign: 'center !important',
    // },
  },
  itemcardBtnStyle1: {
    padding: "10px",
    textAlign: "center !important",
    margin: "auto !important",
    fontFamily: "InterSemiBold !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "12px !important",
    cursor: "pointer !important",
    color: "#fff !important",
    zindex: "1",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize !important",
  },

  // collections style start code---
  collectionHeadertextStyle: {
    textAlign: "left",
    overflow: "hidden",
    height: "20px",
    width: "20vw",
    fontFamily: "InterSemiBlod !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "10px",
  },

  itemsPrice: {
    fontFamily: "InterRegular",
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
}));

export default CollectionsStyles;
