import React, { useEffect, useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import HeaderLogo from "../../Images/redmango-Header-logo.svg";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  CircularProgress,
  InputBase,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { green } from "@mui/material/colors";
import { useEthers, useEtherBalance } from "@usedapp/core";
import CollectionHeaderStyles from "./CollectionHeaderStyles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../store/actions/nfts";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const CollectionHeader = (props) => {
  const classes = CollectionHeaderStyles();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //search functionality code start--//
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const timer = useRef();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector((state) => state.nfts);
  const search = useLocation().search;
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  const inputUrl = new URLSearchParams(search).get("query");

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (inputUrl) {
      setLoading(true);
      props.setLoading(true);
      props.setInput(inputUrl);

      // if (props?.assets?.length < 1) {
      onSearchQuery(inputUrl);
      // }
      setLoading(false);
      props.setLoading(false);
    }
  }, [inputUrl]);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      props.setLoading(true);
      let input = new URLSearchParams(search).get("query");
      props.setInput(input);
      if (props?.assets?.length < 1) {
        await onSearchQuery(input);
      }
      setLoading(false);
      props.setLoading(false);
    }
    getData();
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      props.setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  const handleWallet = () => {
    activateBrowserWallet();
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const onSearchQuery = async (searchQuery) => {
    props.setFilter("30%");
    let input = searchQuery ? searchQuery : props.input;
    let fetchUrl = `https://api.redmango.xyz/api/v1/assets/search?query=${input}&limit=100&`;
    let fetchUrl2 = `https://api.redmango.xyz/api/v1/collections/search?query=${input}&limit=100&`;
    setLoading(true);
    await axios
      .get(fetchUrl)
      .then((res) => {
        if (res && res.data) {
          props.setNextAssetsLink(res.data?.links?.next);
          props.setAssets(res?.data?.results || []);
          props.setAssetsCount(res.data?.total_count || 0);
        }
      })
      .catch((err) => {
        if (err) {
          props.setAssets([]);
        }
      });
    await axios
      .get(fetchUrl2)
      .then((res1) => {
        if (res1 && res1.data) {
          props.setNextCollectionsLink(res1.data?.links?.next);
          props.setCollections(res1?.data?.results || []);
          props.setCollectionsCount(res1.data?.total_count || 0);
        }
      })
      .catch((err) => {
        if (err) {
          props.setCollections([]);
        }
      });

    setLoading(false);
    props.setLoading(false);
    // navigate('/explore', { state: input });
    if (input !== inputUrl) await navigate(`/nft-search?query=${input}`);
  };

  // useEffect(() => {
  //     if (props.pageFlag) {
  //         let result = '1000';
  //         let offset = 0;
  //         while (offset !== 1000) {
  //             offset = offset + 100;
  //             let url = `https://api.redmango.xyz/api/v1/assets/search?query=${props.input}&limit=100&offset=${offset}`
  //             let url2 = `https://api.redmango.xyz/api/v1/collections/search?query=${props.input}&limit=100&offset=${offset}`
  //             onSearchQuery(url, url2)
  //         }
  //         let url = `https://api.redmango.xyz/api/v1/assets/search?query=${props.input}&limit=100&`
  //         let url2 = `https://api.redmango.xyz/api/v1/collections/search?query=${props.input}&limit=100&`
  //         onSearchQuery(url, url2)
  //         // if (props.page - 1) {
  //         //     let offset = (props.page + 1) * 100
  //         //     let url = `https://api.redmango.xyz/api/v1/assets/search?query=${input}&limit=100&offset=${offset}`
  //         //     let url2 = `https://api.redmango.xyz/api/v1/collections/search?query=${input}&limit=100&offset=${offset}`
  //         //     onSearchQuery(url, url2)
  //         // } else {
  //         //     let url = `https://api.redmango.xyz/api/v1/assets/search?query=${input}&limit=100&`
  //         //     let url2 = `https://api.redmango.xyz/api/v1/collections/search?query=${input}&limit=100&`
  //         //     onSearchQuery(url, url2)
  //         // }
  //     }
  // }, [props.page, props.pageFlag])

  const onChangeInput = (e) => {
    setLoading(false);
    props.setInput(e.target.value);
    props.setFilter("none");
  };

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: props.mode === "light" ? "#FFFBFB" : "#000",
        // backgroundColor: "#FFFBFB",
        boxShadow: "0px 4px 4px 0px #0000001A",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {!expandSearch && (
            <Link to="/" style={{ cursor: "pointer" }}>
              <img
                src={HeaderLogo}
                alt="logo"
                className={classes.colleheaderlogoStyle}
              />
            </Link>
          )}
          <Grid
            item
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              margin: "auto",
              padding: 0,
            }}
          >
            <Paper className={classes.collecSearchSytles} component="form">
              <SearchIcon />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={props.input}
                autoFocus="true"
                // placeholder={query}
                // classes={{
                //     root: classes.inputRoot,
                //   }}

                inputProps={{ "aria-label": "search google maps" }}
                onChange={onChangeInput}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    props.setLoading(true);
                    setExpandSearch(false);
                    onSearchQuery();
                  }
                }}
              />
              {/* {props.loading && (
                            <CircularProgress
                                size={68}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: 200,
                                    right: 620,
                                    // left: -10,
                                    zIndex: 99999,
                                    opacity: 1,
                                    filter: 'none'
                                }}
                                style={{ filter: 'none', marginLeft: '20px' }}
                            />
                        )} */}
            </Paper>
          </Grid>

          <Grid
            item
            style={{
              width: "100%",
              margin: 0,
              padding: 0,
              alignItems: "center",
            }}
            sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
          >
            {expandSearch ? (
              <Paper className={classes.collecSearchSytles} component="form">
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  value={props.input}
                  autoFocus="true"
                  // placeholder={query}
                  // classes={{
                  //     root: classes.inputRoot,
                  //   }}

                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={onChangeInput}
                  onBlur={() => setExpandSearch(false)}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      props.setLoading(true);
                      setExpandSearch(false);
                      onSearchQuery();
                    }
                  }}
                />
                {/* {props.loading && (
                            <CircularProgress
                                size={68}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: 200,
                                    right: 620,
                                    // left: -10,
                                    zIndex: 99999,
                                    opacity: 1,
                                    filter: 'none'
                                }}
                                style={{ filter: 'none', marginLeft: '20px' }}
                            />
                        )} */}
              </Paper>
            ) : (
              <SearchIcon
                onClick={() => setExpandSearch(true)}
                style={{ color: "#000", position: "fixed", right: 10 }}
              />
            )}
          </Grid>

          <Snackbar
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            message={message}
          >
            <Alert
              severity={severity}
              sx={{ width: "100%", marginTop: "7rem" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default CollectionHeader;
