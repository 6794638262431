import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import dummyimg2 from "../../Images/RedLogo.png";
import CollectionHeaderStyles from "../CollectionHeader/CollectionHeaderStyles";
import AspectRatioBox from "../AspectRatio/aspectRatioBox";
import Nftsort from "../Nftsort/UnstyledSelectSimple";
import TabScrollButton from "@mui/material/TabScrollButton";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NoImage from "../../Images/no-img.jpg";
import { createTheme, Pagination } from "@mui/material";
import LazyLoad from "react-lazy-load";
import axios from "axios";
import { Helmet } from "react-helmet";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@mui/material";

import { TabContext, TabPanel } from "@mui/lab";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { FaEthereum } from "react-icons/fa";
import CollectionsStyles from "./CollectionsStyles";
import CollectionHeader from "../CollectionHeader";
import { eventWrapper } from "@testing-library/user-event/dist/utils";
import { PropaneSharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
//import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroll-component";

function Collections(props) {
  const classes = CollectionsStyles();
  // const history = useHistory();
  const [value, setValue] = useState("0");
  const [isLike, setIsLike] = useState(false);
  const [colors, setColors] = useState([]);

  const [query, setQuery] = useState("");
  const [style, setStyle] = React.useState({ display: "none" });
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("none");

  const handlePage = (value) => {
    if (value !== 1 || value < page) {
      props.setPageFlag(true);
      props.setPage(value);
      setPage(value);
    }
  };

  const like = () => setIsLike(!isLike);

  const getColors = (colors) => {
    setColors((c) => [...c, ...colors]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showButtton = (e, index) => {
    setStyle({ display: "block" });
  };
  const hideButtton = (e, index) => {
    setStyle({ display: "none" });
  };

  const [nftPrice, setNftprice] = React.useState("");

  const onClickScroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [value]);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
        props.setPageFlag(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const fetchMoreAssetsData = async () => {
    console.log("In fetchmoredata functio calling", props.nextAssetsLink);
    await axios
      .get(props.nextAssetsLink)
      .then((res) => {
        if (res && res.data && res?.data?.results.length > 0) {
          if (props.assets && props.assets.length > 0) {
            let data = [...props.assets, ...res.data?.results];
            props.setAssets(data);
            console.log("fetchMoreAssetsData Data --- ", data.length);
          } else {
            props.setAssets(res.data?.results);
          }
          props.setNextAssetsLink(res.data?.links?.next);
          props.setAssetsCount(res.data?.total_count || 0);
        }
      })
      .catch((err) => {
        if (err) {
          props.setAssets(null);
        }
      });
    // }
  };

  //nft name return start code//
  const shortName = (name) => {
    if (window.innerWidth > 700) {
      return name.length > 20 ? name.slice(0, 20) + "..." : name;
    } else {
      return name.length > 10 ? name.slice(0, 8) + "..." : name;
    }
  };

  const getName = (item) => {
    let name = "";
    if (item?.metadata?.name) {
      name = shortName(item?.metadata?.name);
    } else if (item?.nft_id) {
      let { nft_id } = item;
      let tempName = nft_id.split("/");
      name = "#" + tempName[tempName.length - 1];
    }
    return name;
  };
  //Nft name return end code//

  // const getPrice = (value) => {
  //     // 1000000000000000
  //     if (value) {
  //         if ((value / (10 ** 18)) === parseInt(value / (10 ** 18)))
  //             return (value / (10 ** 18))
  //         return (value / (10 ** 18)).toFixed(3)
  //     }
  //     return 0
  // }

  const getPrice = (value) => {
    if (value)
      if (value % 1 !== 0) {
        return (value / 1000000000000000000).toFixed(3);
      } else {
        return value / 1000000000000000000;
      }
    return "";
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redmango | Web3 and NFT search engine</title>
        <meta name="title" content="Redmango | Web3 and NFT search engine" />
        <meta
          name="description"
          content="Search your favorite NFTs in one place! Redmango is a Web3 search engine where you can search for NFTs and Collections"
        />
        <meta name="keywords" content="Web3 NFT Search Engine" />
      </Helmet>

      <CollectionHeader
        setLoading={setLoading}
        loading={loading}
        setFilter={setFilter}
        {...props}
      />

      <Grid
        container
        spacing={2}
        style={{
          marginTop: "3rem",
          backgroundColor: props.mode === "light" ? "#FFFBFB" : "#000",
          // background: "#FFFBFB",
          filter: filter,
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          {/* <Grid item className={classes.thf} > */}
          <Box
            style={{
              // background: "#FFFBFB",
              padding: "0.5rem",
              position: "fixed",
              width: "100%",
              margin: "auto",
              zIndex: 999,
              display: "none",
            }}
          >
            {/* <Paper className={classes.collecSearchSytles1} style={{ width: "80%", margin: 'auto' }}
                            component="form">
                            <SearchIcon />
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={query}
                                inputProps={{ 'aria-label': 'search google maps' }}
                                // onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault()
                                        // searchQuery(ev)
                                    }
                                }}
                            />
                            {/* {loading && ( */}
            {/* <CircularProgress
                            size={68}
                            sx={{
                                // color: green[500],
                                position: 'absolute',
                                top: 200,
                                // left: -10,
                                zIndex: 99999,
                                opacity: 1,
                                filter: 'none'
                            }}
                            style={{ filter: 'none' }}
                        /> */}
            {/* )} */}
            {/* </Paper> */}
          </Box>
          {/* </Grid> */}
          <TabContext
            value={value}
            style={{
              // position: "fixed",
              width: "100%",
              // marginTop: "3rem",
              zIndex: 999,
              // background: "red"
            }}
          >
            <Box
              style={{
                borderBottom: "1px solid #FFCDD4CC",
                borderColor: "divider",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "-0.5rem",
                marginBottom: "3rem",
                position: "fixed",
                width: "100%",
                zIndex: 999,
                padding: "10px",
                backgroundColor: props.mode === "light" ? "#FFFBFB" : "#000",
                // background: "#FFFBFB",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#db303f" } }}
              >
                <Tab
                  label="Items"
                  value={"0"}
                  className={classes.itemTabStyle}
                  style={{ fontFamily: "SomaticRounded" }}
                />
                <Tab
                  label="Collections"
                  value={"1"}
                  className={classes.itemTabStyle}
                />
              </Tabs>
            </Box>
            {showTopBtn && (
              <ExpandLessIcon
                style={{
                  color: "#fff",
                  fontSize: "60px",
                  borderRadius: "30px",
                  background: "#DB303F",
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  zIndex: "9999",
                }}
                onClick={onClickScroll}
              />
            )}

            {/*<Grid container>
                            <Grid
                                item
                                md={12}
                                lg={12}
                                sm={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "4rem",
                                }}
                            >
                                <Nftsort />
                            </Grid>
                            </Grid>*/}

            <TabPanel
              value="1"
              index="1"
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3rem",
              }}
            >
              <Grid container spacing={3} className={classes.itemGridStyle}>
                {props.collections && props.collections.length > 0 ? (
                  props.collections.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      key={item._id}
                    >
                      <a
                        href={item?.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              height: 350,
                              border: "1px solid gray",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "8px",
                            }}
                            className={classes.card1}
                            style={{
                              backgroundColor:
                                props.mode === "light"
                                  ? "rgb(23 30 46/var(--tw-bg-opacity))"
                                  : "#000",
                            }}
                          >
                            <>
                              <AspectRatioBox ratio={1.5}>
                                {item?.banner_image_url ? (
                                  <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="140"
                                    image={item?.banner_image_url}
                                  />
                                ) : (
                                  <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="140"
                                    style={{ backgroundColor: "#009cfe" }}
                                    image={NoImage}
                                  />
                                )}
                              </AspectRatioBox>

                              <CardContent
                                style={{
                                  minHeight: "95px",
                                  maxHeight: "100px",
                                }}
                              >
                                <Grid
                                  container
                                  className={classes.itemImgStyle}
                                >
                                  <Grid item xs={3}>
                                    {item?.image_url ? (
                                      <img
                                        // src={item?.featured_image_url}
                                        src={item?.image_url}
                                        alt="img"
                                        width={50}
                                        height={50}
                                        style={{ borderRadius: "25px" }}
                                      />
                                    ) : (
                                      <img
                                        src={dummyimg2}
                                        alt="img"
                                        width="50"
                                        height="50"
                                        className={classes.itemImgStyle1}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Grid container item xs={12}>
                                      <Grid
                                        item
                                        xs={12}
                                        className={classes.itemHeaderTextStyle}
                                      >
                                        <Typography
                                          style={{
                                            color:
                                              props.mode === "light"
                                                ? "#000"
                                                : "#fffbfb ",
                                          }}
                                          variant="h6"
                                          className={
                                            classes.itemHeaderTextStyle1
                                          }
                                        >
                                          {item?.name?.length > 20
                                            ? item?.name?.slice(0, 18) + "..."
                                            : item?.name}
                                        </Typography>
                                      </Grid>
                                      {/* <Grid item xs={4} style={{ display: "flex", justifyContent: 'center' }}>
                                                                                        </Grid> */}
                                    </Grid>
                                    {/*  <Typography className={classes.itemcardDescStyle}>
                                                                                            {item?.description?.slice(0, 88)}...
                                                                                        </Typography>*/}
                                  </Grid>
                                  <Grid container xs={12}>
                                    <Grid item xs={3}>
                                      <Typography
                                        style={{
                                          color:
                                            props.mode === "light"
                                              ? "#000"
                                              : "#fffbfb",
                                        }}
                                        className={classes.typoStyles1}
                                      >
                                        Items
                                      </Typography>
                                      <Typography
                                        style={{
                                          color:
                                            props.mode === "light"
                                              ? "#000"
                                              : "#fffbfb",
                                        }}
                                        className={classes.typoStyles}
                                      >
                                        {item?.stats?.count >= 1000
                                          ? parseFloat(
                                              (
                                                item?.stats?.count / 1000
                                              ).toFixed(2)
                                            ) + "K"
                                          : item?.stats?.count}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        style={{
                                          color:
                                            props.mode === "light"
                                              ? "#000"
                                              : "#fffbfb",
                                        }}
                                        className={classes.typoStyles1}
                                      >
                                        Owner
                                      </Typography>
                                      <Typography
                                        className={classes.typoStyles}
                                      >
                                        {item?.stats?.num_owners > 1000
                                          ? parseFloat(
                                              (
                                                item?.stats?.num_owners / 1000
                                              ).toFixed(1)
                                            ) + "K"
                                          : item?.stats?.num_owners}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        style={{
                                          color:
                                            props.mode === "light"
                                              ? "#000"
                                              : "#fffbfb",
                                        }}
                                        className={classes.typoStyles1}
                                      >
                                        Floor
                                      </Typography>
                                      <Typography
                                        className={classes.typoStyles}
                                      >
                                        <FaEthereum
                                          style={{
                                            fontSize: "8px",
                                            marginLeft: "-0.1rem",
                                            marginTop: "-3.3rem",
                                          }}
                                        />{" "}
                                        {item?.stats?.floor_price < 0.1
                                          ? item?.stats?.floor_price < 0.01
                                            ? " < " + 0.01
                                            : // ? 0.01
                                              (item?.stats?.floor_price).toFixed(
                                                2
                                              )
                                          : (item?.stats?.floor_price).toFixed(
                                              1
                                            )}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        style={{
                                          color:
                                            props.mode === "light"
                                              ? "#000"
                                              : "#fffbfb",
                                        }}
                                        className={classes.typoStyles1}
                                      >
                                        Volume
                                      </Typography>
                                      <Typography
                                        className={classes.typoStyles}
                                      >
                                        <FaEthereum
                                          style={{ fontSize: "8px" }}
                                        />{" "}
                                        {item?.stats?.total_volume > 1000
                                          ? (
                                              item?.stats?.total_volume / 1000
                                            ).toFixed(2) + "K"
                                          : (item?.stats?.total_volume).toFixed(
                                              2
                                            )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>

                              <Box sx={{ width: 2, bgcolor: "divider" }} />

                              <CardActions className={classes.itemcardBtnStyle}>
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Button
                                    level="body3"
                                    className={classes.itemcardBtnStyle1}
                                  >
                                    View Opensea
                                  </Button>
                                </a>
                              </CardActions>
                            </>
                          </Card>
                        )}
                      </a>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    style={{
                      padding: "50px",
                      fontSize: "20px",
                      fontFamily: "InterRegular",
                      color: "#666",
                      textAlign: "center",
                      marginTop: "3.2rem",
                    }}
                  >
                    <b
                      style={{
                        fontFamily: "InterSemiBold",
                        fontSize: "22px",
                        color: "#333",
                        padding: "10px",
                      }}
                    >
                      {" "}
                      No Collections Found
                    </b>
                    <br />
                    We couldn't find any collections matching your query.
                    <br />
                    Try another query
                  </Typography>
                )}
              </Grid>
            </TabPanel>
            <TabPanel
              value="0"
              index="0"
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // minWidth: "80%",
              }}
            >
              {loading ? (
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        margin: "auto",
                        height: 350,
                        width: 320,
                        border: "1px solid gray",
                        borderRadius: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        margin: "auto",
                        height: 350,
                        width: 320,
                        border: "1px solid gray",
                        borderRadius: "8px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        margin: "auto",
                        height: 350,
                        width: 320,
                        border: "1px solid gray",
                        borderRadius: "8px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        margin: "auto",
                        height: 350,
                        width: 320,
                        border: "1px solid gray",
                        borderRadius: "8px",
                      }}
                    />
                  </Grid>
                </Grid>
              ) : props.assets && props.assets.length > 0 ? (
                <InfiniteScroll
                  dataLength={props.assets.length}
                  next={fetchMoreAssetsData}
                  hasMore={props.nextAssetsLink}
                  loader={<h4>Loading...</h4>}
                  className={classes.infiniteSrlStyle}
                  // style={{ minWidth: '95vw' }}
                >
                  <Grid
                    container
                    spacing={3}
                    className={classes.itemGridStyle1}
                  >
                    {props.assets.map((item, index) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        key={item._id}
                        sx={{
                          width: "100%",
                          height: "100%",
                          // maxWidth: 345,
                          // maxHeight: 550,
                          borderRadius: "8px",
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={item?.permalink}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none", width: "100%" }}
                        >
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              sx={{
                                height: 350,
                                border: "1px solid gray",
                                borderRadius: "8px",
                              }}
                            />
                          ) : (
                            <Card
                              sx={{
                                width: "100%",
                                height: "100%",
                                // maxWidth: 345,
                                // maxHeight: 550,
                                borderRadius: "8px",
                              }}
                              className={classes.card1}
                            >
                              <>
                                <AspectRatioBox ratio={1.1}>
                                  {item?.metadata?.image_url ? (
                                    <CardMedia
                                      component="img"
                                      alt="green iguana"
                                      height="100"
                                      image={item?.metadata?.image_url}
                                    />
                                  ) : (
                                    <CardMedia
                                      component="img"
                                      alt="green iguana"
                                      height="100"
                                      // width="300px"
                                      style={{ backgroundColor: "#009cfe" }}
                                      image={NoImage}
                                    />
                                  )}
                                </AspectRatioBox>
                                <CardContent
                                  style={{
                                    minHeight: "70px",
                                    maxHeight: "100px",
                                  }}
                                  className={classes.contentStyle}
                                >
                                  <Grid
                                    container
                                    className={classes.itemImgStyle2}
                                    style={{ marginBottom: "-1rem" }}
                                  >
                                    <Grid item xs={12}>
                                      <Grid container item xs={12}>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            textAlign: "left",
                                            color:
                                              props.mode === "light"
                                                ? "#333333"
                                                : "rgb(156 163 175/var(--tw-text-opacity))",
                                          }}
                                        >
                                          <Typography
                                            className={classes.itemHeaderStyle1}
                                          >
                                            {" "}
                                            {getName(item)}
                                            {/* {
                                                                                                    window.innerWidth > 700 ?
                                                                                                        item?.metadata?.name?.length > 20 ?
                                                                                                            item?.metadata?.name?.slice(0, 20) +
                                                                                                            "..." : item?.metadata?.name

                                                                                                        :
                                                                                                        item?.metadata?.name?.length > 10 ?
                                                                                                            item?.metadata?.name?.slice(0, 8) +
                                                                                                            "..." :
                                                                                                            item?.metadata?.name
                                                                                                } */}
                                          </Typography>
                                          <Typography
                                            style={{
                                              color:
                                                props.mode === "light"
                                                  ? "#333333"
                                                  : "rgb(255 255 255/var(--tw-text-opacity))",
                                            }}
                                            className={
                                              classes.itemHeaderTextStyle2
                                            }
                                          >
                                            {" "}
                                            {item?.collection?.name?.length > 15
                                              ? item?.collection?.name?.slice(
                                                  0,
                                                  12
                                                ) + "..."
                                              : item?.collection?.name}
                                          </Typography>
                                        </Grid>
                                        {/* <Grid item xs={4} className={classes.itemGridStyle}>
                                                                                    </Grid> */}
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: "0.1rem",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        {item?.redmango_price > 0 ? (
                                          <Typography
                                            style={{
                                              color:
                                                props.mode === "light"
                                                  ? "#000"
                                                  : "rgb(255 255 255/var(--tw-text-opacity))",
                                            }}
                                            className={classes.priceTextStyle}
                                          >
                                            {" "}
                                            Price :{" "}
                                            <FaEthereum
                                              style={{ fontSize: "12px" }}
                                            />{" "}
                                            {getPrice(item?.redmango_price)}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            className={classes.priceTextStyle}
                                          >
                                            Not for Sale
                                          </Typography>
                                        )}
                                        <br />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <Box sx={{ width: 2, bgcolor: "divider" }} />
                                <CardActions
                                  className={classes.itemcardBtnStyle}
                                >
                                  <a
                                    href={item?.permalink}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      level="body3"
                                      className={classes.itemcardBtnStyle1}
                                    >
                                      View Opensea
                                    </Button>
                                  </a>
                                </CardActions>
                              </>
                            </Card>
                          )}
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </InfiniteScroll>
              ) : (
                <Grid container spacing={3} className={classes.itemGridStyle}>
                  <Typography
                    style={{
                      padding: "50px",
                      fontSize: "19px",
                      fontFamily: "InterRegular",
                      color: "#666",
                      textAlign: "center",
                    }}
                  >
                    <b
                      style={{
                        fontFamily: "InterSemiBold",
                        fontSize: "22px",
                        color: "#333",
                        padding: "10px",
                      }}
                    >
                      No NFTs found{" "}
                    </b>
                    <br />
                    We couldn't find any NFTs matching your query.
                    <br />
                    Try another query
                  </Typography>
                </Grid>
              )}
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
}

export default Collections;
