import { makeStyles } from "@mui/styles";

const HeaderStyles = makeStyles((theme) => ({
    logoStyle: {
        cursor: "pointer", width: '180px',
        [theme.breakpoints.down("sm")]: {
            // width: '160px',
            display: "none"
        },
    },

}));

export default HeaderStyles;