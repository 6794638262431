import { Box, CircularProgress, InputBase, Paper } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { green } from "@mui/material/colors";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import HomepageLogo from "../../Images/redmango-homepage.svg";
import SearchStyles from "./SearchStyles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../store/actions/nfts";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Search = (props) => {
  const classes = SearchStyles();
  const navigate = useNavigate();
  const [assestFlag, setAssetsFlag] = useState(false);
  const [collectionFlag, setCollectionFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector((state) => state.nfts);

  const timer = useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    return () => {
      //   props.setInput("");
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  const goExplore = () => {
    navigate("/explore");
  };
  const goCreate = () => {
    navigate("/create");
  };

  useEffect(() => {
    if (assestFlag && collectionFlag) {
      navigate(`/nft-search?query=${props.input}`);
    }
  }, [assestFlag, collectionFlag]);

  const onSearchQuery = async () => {
    setLoading(true);
    await axios
      .get(
        `https://api.redmango.xyz/api/v1/assets/search?query=${props.input}&limit=100&`
      )
      .then((res) => {
        if (res && res.data) {
          props.setNextAssetsLink(res.data?.links?.next);
          props.setAssets(res?.data?.results || []);
          props.setAssetsCount(res.data?.total_count || 0);
        }
        setAssetsFlag(true);
      })
      .catch((err) => {
        if (err) {
          props.setAssets(null);
          setAssetsFlag(true);
          setLoading(false);
        }
      });
    await axios
      .get(
        `https://api.redmango.xyz/api/v1/collections/search?query=${props.input}&limit=100&`
      )
      .then((res1) => {
        if (res1 && res1.data) {
          props.setNextCollectionsLink(res1.data?.links?.next);
          props.setCollections(res1?.data?.results || []);
          props.setCollectionsCount(res1.data?.total_count || 0);
        }
        setCollectionFlag(true);
      })
      .catch((err) => {
        if (err) {
          props.setCollections(null);
          setCollectionFlag(true);
          setLoading(false);
        }
      });
  };
  const onChangeInput = (e) => {
    setLoading(false);
    props.setInput(e.target.value);
  };
  return (
    <>
      <Box
        style={{
          filter: loading ? "opacity(30%)" : "none",
          marginTop: "4rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <img src={HomepageLogo} alt="" className={classes.logoStyle} />
        </Box>
        <Box>
          <Paper component="form" className={classes.searchSytles}>
            <SearchIcon />
            <InputBase
              style={{ fontFamily: "InterRegular" }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search NFTs & Collections"
              inputProps={{ "aria-label": "search google maps" }}
              value={props.input}
              onChange={onChangeInput}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  onSearchQuery();
                }
              }}
              disabled={loading}
            />
          </Paper>
          {/* <Snackbar
                        autoHideDuration={3000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={open}
                        message={message}
                    >
                        <Alert severity={severity} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                        </Snackbar>*/}
        </Box>
      </Box>
      {loading && (
        <CircularProgress
          size={68}
          sx={{
            color: green[500],
            position: "absolute",
            top: 200,
            // left: -10,
            zIndex: 99999,
            opacity: 1,
            filter: "none",
          }}
          style={{ filter: "none" }}
        />
      )}
    </>
  );
};

export default Search;
