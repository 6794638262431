import { actionTypes } from '../actions'

const INITIAL_STATE = {
    searchQuery: null
}

export const nfts = (state = INITIAL_STATE, action) => {
    const { type, payload } = action

    switch (type) {
        case actionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: payload
            }
        default:
            return state;
    }
}