import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Search from "./Search";
import Header from "./Header";
import { Helmet } from "react-helmet";

function Home(props) {
  useEffect(() => {
    props.setInput("");
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redmango | Web3 and NFT search engine</title>
        <meta name="title" content="Redmango | Web3 and NFT search engine" />
        <meta
          name="description"
          content="Search your favorite NFTs in one place! Redmango is a Web3 search engine where you can search for NFTs and Collections"
        />
        <meta name="keywords" content="Web3 NFT Search Engine" />
      </Helmet>
      {/* <Header /> */}

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "6rem",
          minHeight: "100vh",
          backgroundColor: props.mode === "light" ? "#FFFBFB" : "#000",
        }}
      >
        <Search {...props} />
      </Box>
    </>
  );
}

export default Home;
