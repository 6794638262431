import { makeStyles } from "@mui/styles";

const SearchStyles = makeStyles((theme) => ({
    logoStyle: {
        cursor: "pointer", margin: 'auto',
        [theme.breakpoints.down("sm")]: {
            width: '250px',
            margin: 'auto',
        },
    },
    searchSytles: {
        border: '1px solid #FFCDD4 !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '55vw !important',
        borderRadius: '8px !important',
        padding: "12px !important",
        boxShadow: "none !important",
        [theme.breakpoints.down("sm")]: {
            alignItems: 'center !important',
            width: '100% !important',
            margin: 'auto !important',
        },
        '&:hover': {
            boxShadow: '0px 4px 8px 1px #0000001A !important',
        }
    },

}));

export default SearchStyles;