import { makeStyles } from "@mui/styles";

const CollectionHeaderStyles = makeStyles((theme) => ({
    colleheaderlogoStyle: {
        cursor: "pointer", width: '180px',
        [theme.breakpoints.down("sm")]: {
            // width: '160px',
            display: 'block'
        },
    },


    collecSearchSytles: {

        margin: "auto !important",
        border: '1px solid #FFCDD4 !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '40vw !important',
        borderRadius: '8px !important',
        padding: "5px !important",
        boxShadow: "none !important",
        [theme.breakpoints.down("sm")]: {
            alignItems: 'center !important',
            width: '100vw !important',
            margin: 'auto !important',
            // display: 'none !important',
            '&:focused': { width: '100vw' },
        },
        '&:hover': {
            boxShadow: '0px 4px 8px 1px #0000001A !important',
        },

    },
    thf: {
        display: 'flex !important',
        [theme.breakpoints.down("sm")]: {
            display: "flex !important",
        }
    },
    collecSearchSytles1: {
        margin: "auto !important",
        border: '1px solid #FFCDD4 !important',
        alignItems: 'center !important',
        width: '40vw !important',
        borderRadius: '8px !important',
        padding: "5px !important",
        boxShadow: "none !important",
        display: 'none',
        opacity: '0',
        width: '70% !important', transition: theme.transitions.create("width"),
        [theme.breakpoints.down("sm")]: {
            alignItems: 'center !important',
            width: '70% !important',
            margin: 'auto !important',
            opacity: '1',
            display: 'flex',
        },
        "& .MuiInputBase-input:focus": {
            width: "180px",
        },
        '&:hover': {
            boxShadow: '0px 4px 8px 1px #0000001A !important',
        }
    },
    inputRoot: {
        border: "0px solid silver",
        borderRadius: "4px",
        width: "100%",
        padding: "5px",
        paddingTop: "7px",
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            "& .MuiInputBase-input:focus": {
                width: "110ch",
            },
        },
    },

}));

export default CollectionHeaderStyles;