import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Collections from "./components/Collections";
import theme from "./AppTheme";
import "./assets/fonts/Inter-Regular.otf";
import "./assets/fonts/Inter-SemiBold.otf";
import "./assets/fonts/Inter-SemiBoldItalic.otf";
import "./assets/fonts/Inter-Medium.otf";
import Store from "./store";
import { Provider } from "react-redux";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Box, IconButton } from "@mui/material";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                background: {
                  default: "#FFFBFB",
                }, // palette values for light mode
              }
            : {
                background: {
                  default: "#000",
                },
              }),
        },
      }),
    [mode]
  );
  const colorModeChange = React.useContext(ColorModeContext);

  const [page, setPage] = useState(1);
  const [pageFlag, setPageFlag] = useState(false);
  const [assets, setAssets] = useState([]);
  const [collections, setCollections] = useState([]);
  const [input, setInput] = useState("");
  const [newArray, setNewArray] = useState([]);
  const [nextAssetsLink, setNextAssetsLink] = useState(null);
  const [nextCollectionsLink, setNextCollectionsLink] = useState(null);
  const [assetsCount, setAssetsCount] = useState(0);
  const [collectionsCount, setCollectionsCount] = useState(0);

  // React.useEffect(() => {
  //   setTimeout(() =>
  //     setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
  //   );
  // }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <Provider store={Store}>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              // width: "100%",
              alignItems: "center",
              justifyContent: "end",
              // bgcolor: "background.default",
              color: "text.primary",
              // borderRadius: 1,
              p: 2,
              zIndex: 10000,
            }}
            style={{ position: "fixed", zIndex: 9999, right: 20, top: -6 }}
          >
            {/* {theme.palette.mode} mode */}
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Box>
          <Router>
            {/* <Header /> */}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    assets={assets}
                    setAssets={setAssets}
                    collections={collections}
                    setCollections={setCollections}
                    input={input}
                    setInput={setInput}
                    newArray={newArray}
                    setNewArray={setNewArray}
                    nextAssetsLink={nextAssetsLink}
                    setNextAssetsLink={setNextAssetsLink}
                    nextCollectionsLink={nextCollectionsLink}
                    setNextCollectionsLink={setNextCollectionsLink}
                    assetsCount={assetsCount}
                    setAssetsCount={setAssetsCount}
                    collectionsCount={collectionsCount}
                    setCollectionsCount={setCollectionsCount}
                    mode={mode}
                  />
                }
              />
              <Route
                exact
                path="/nft-search"
                element={
                  <Collections
                    page={page}
                    setPage={setPage}
                    pageFlag={pageFlag}
                    setPageFlag={setPageFlag}
                    assets={assets}
                    setAssets={setAssets}
                    collections={collections}
                    setCollections={setCollections}
                    input={input}
                    setInput={setInput}
                    newArray={newArray}
                    setNewArray={setNewArray}
                    nextAssetsLink={nextAssetsLink}
                    setNextAssetsLink={setNextAssetsLink}
                    nextCollectionsLink={nextCollectionsLink}
                    setNextCollectionsLink={setNextCollectionsLink}
                    assetsCount={assetsCount}
                    setAssetsCount={setAssetsCount}
                    collectionsCount={collectionsCount}
                    setCollectionsCount={setCollectionsCount}
                    mode={mode}
                  />
                }
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </Provider>
    </ColorModeContext.Provider>
  );
}

export default App;
